import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ModuleWithProviders } from '@angular/core';
import { NotFoundComponent } from './not-found/not-found.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'sunset',
    loadChildren: () => import('./sunset/sunset.module').then(m => m.SunsetModule)
  },
  {
    path: 'checklist',
    loadChildren: () => import('./checklist/checklist.module').then(m => m.ChecklistModule)
  },
  {
    path: 'power',
    loadChildren: () => import('./progress/progress.module').then(m => m.ProgressModule)
  },
 /* {
    path: 'workbench',
    loadChildren: () => import('./loadout/loadout.module').then(m => m.LoadoutModule)
  },*/
  {
    path: 'vault',
    loadChildren: () => import('./vault-cleaner/vault-cleaner.module').then(m => m.VaultCleanerModule)
  },
  {
    path: 'testing',
    loadChildren: () => import('./testing-grounds/testing.module').then(m => m.TestingModule)
  },
  {
    path: 'appraiser',
    loadChildren: () => import('./appraiser/appraiser.module').then(m => m.AppraiserModule)
  },
  {
    path: 'loot',
    loadChildren: () => import('./loot/loot.module').then(m => m.LootModule)
  },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'home', redirectTo: '' },
  { path: '**', component: NotFoundComponent }
];

export const AppRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes);
