import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ErrorService } from '../services/error.service';
import { ModalService } from '../services/modal.service';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { CoreService } from '../services/core.service';
import { logError } from '../console';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  errorDisplayed = false;

  constructor(private zone: NgZone, private errorService: ErrorService, private modal: ModalService, private coreService: CoreService) {
  }

  handleError(error: Error) {
    logError(error);
    if (this.errorDisplayed || !this.errorService.isCritical()) {
      return;
    }
    this.zone.run(() => {
      this.errorDisplayed = true;
      this.modal.create({
        component: ErrorModalComponent,
        title: 'An error occurred',
        data: {
          error
        }
      }).subscribe(() => {
        this.coreService.stopWatchingErrors();
        this.errorDisplayed = false;
      });
    });
  }

}
