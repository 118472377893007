<div class="privacy">
  <h1>Terms and conditions</h1>
  <div class="content">
    <label>These terms and conditions ("Terms", "Agreement") are an agreement between Website Operator ("Website Operator", "us", "we" or "our") and you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of your use of the <a href="https://destinyrecipes.com" target="_blank" rel="nofollow">destinyrecipes.com</a> website and any of its products or services (collectively, "Website" or "Services").</label>

    <div>
      <h2>Accounts and membership</h2>
      <label>Destiny Recipes uses the Bungie Authentication system to safely authenticate users. No account information, password or token related to your personal information are stored on our backend services other than your public Destiny Membership ID.</label>
      <label>Destiny Recipes uses your Destiny Membership ID to store your preferences and data related to the game. This data is stored in a secure database and is only accessible by you.</label>
    </div>

    <div>
      <h2>Links to other websites</h2>
      <label>Although this Website may link to other websites, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services, and content of any other third-parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website. Your linking to any other off-site websites is at your own risk.</label>
    </div>

    <div>
      <h2>Disclaimers of Warranties and limitations on Liability</h2>
      <label>Destiny Recipes services and all content and software associated therewith, or any feature associated with Destiny Recipes are provided "as is" and "as available" with all faults and without warranty of any kind. Destiny Recipes does not guarantee that your use of our services will be unintererrupted or error-free.</label>
      <label>Destiny Recipes CANNOT delete or create content within the game Destiny. Any loss of progress or in-game content can only be done by the User and is entirely in the User's control. Destiny Recipes cannot be responsible for any such loss.</label>
    </div>

    <br><br>
    <label>This document was last updated on August 24, 2024</label>
  </div>
</div>
