import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CoreService } from '@Services/core.service';

export interface Recipe {
  title: string;
  icon: IconProp;
  desc: string;
  link: string;
  internalLink?: string;
  tag: string;
}

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class HomeComponent {
  recipes: Recipe[] = [
    {
      title: 'TOOLS.CHECKLIST.NAME',
      icon: ['fas', 'tasks'],
      desc: 'TOOLS.CHECKLIST.DESCRIPTION',
      link: 'https://destinyrecipes.com/checklist',
      tag: 'checklist',
      internalLink: 'checklist'
    },
    {
      title: 'TOOLS.LOOT_COMPANION.NAME',
      icon: ['fab', 'watchman-monitoring'],
      desc: 'TOOLS.LOOT_COMPANION.DESCRIPTION',
      link: 'https://destinyrecipes.com/loot',
      tag: 'loot',
      internalLink: 'loot'
    },
    {
      title: 'TOOLS.VAULT_CLEANER.NAME',
      icon: ['fab', 'buromobelexperte'],
      desc: 'TOOLS.VAULT_CLEANER.DESCRIPTION',
      link: 'https://destinyrecipes.com/vault',
      tag: 'vault',
      internalLink: 'vault'
    },
    {
      title: 'TOOLS.PROGRESS.NAME',
      icon: ['fas', 'align-left'],
      desc: 'TOOLS.PROGRESS.DESCRIPTION',
      link: 'https://destinyrecipes.com/power',
      tag: 'power',
      internalLink: 'power'
    },
/*    {
      title: 'TOOLS.APPRAISER.NAME',
      icon: ['fas', 'microscope'],
      desc: 'TOOLS.APPRAISER.DESCRIPTION',
      link: 'https://destinyrecipes.com/appraiser',
      tag: 'appraiser',
      internalLink: 'appraiser'
    }*/
  ];

  translators = [
    {lang: 'ar', names: ['Codex_', 'monster20'], percentage: 76},
    {lang: 'fr', names: ['HerrExion', 'Jeitt'], percentage: 100},
    {lang: 'zh-chs', names: ['曾祥瑞 (Frozty4628)'], percentage: 89},
    {lang: 'zh-cht', names: ['FirstBLOOD'], percentage: 77},
    {lang: 'de', names: ['nwL', 'Ca†chup', 'korben85'], percentage: 100},
    {lang: 'it', names: ['Theovorn', 'FlyD2'], percentage: 100},
    {lang: 'jp', names: ['R4NDO'], percentage: 100},
    {lang: 'kr', names: ['pronhkim'], percentage: 67},
    {lang: 'nb', names: ['Haza'], percentage: 100},
    {lang: 'pl', names: ['ARTES', 'Pawis', 'inPhamous', 'Laskus', 'WhoAsked'], percentage: 77},
    {lang: 'pt-br', names: ['Nefrons', 'Ledian00'], percentage: 76},
    {lang: 'ru', names: ['solelychloe', 'gevoup', 'JohnWillimas', 'имязип'], percentage: 83},
    {lang: 'sr-cs', names: ['BossManSho', 'Ducko'], percentage: 77},
    {lang: 'es', names: ['Apokalipsys-ZR7', 'MadBullets90'], percentage: 100},
    {lang: 'es-mx', names: ['Eaglemchng', 'GastonKhouri'], percentage: 100}
  ];

  constructor(private router: Router, private core: CoreService) {
    if (this.core.devMode) {
      this.recipes.push({
        title: 'Testing Grounds',
        icon: ['fas', 'vial'],
        desc: 'Test',
        link: 'https://destinyrecipes.com/testing',
        tag: 'testing',
        internalLink: 'testing'
      });
    }
  }

  open(tag: string) {
    this.router.navigate([tag]);
  }
}
